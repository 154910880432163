import React, { useState, useEffect } from "react";

import NorthstarPageLayout from "../../components/NorthstarPageLayout";
import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";
import { EmbeddedApplication } from "../../components/EmbeddedApplication/embeddedApplication";

import Spinner from "../../components/Spinner";

export default function EmbeddedApplicationPage(props) {
  const { id } = props.params;

  const { accessToken } = useAuthContext();

  const { data, isLoading, error } = useNorthstar(
    `/api/embedded-applications/${id}`,
    accessToken,
    {
      withCredentials: true,
      method: "get",
      params: {
        fields: ["origin", "jwt", "pathname"],
      },
    },
  );

  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (error) {
    }
  }, [error?.response?.status]);

  useEffect(() => {
    if (data) {
      setResponse(data.data.attributes);
    }
  }, [data?.data?.id]);

  return (
    <>
      <NorthstarPageLayout title={"View"}>
        <Spinner active={isLoading} />
        {response && (
          <EmbeddedApplication
            origin={new URL(response.origin + response.pathname)}
            jwt={response.jwt}
          />
        )}
      </NorthstarPageLayout>
    </>
  );
}
