import React from "react";

const EmbeddedStreamlitApplication: React.FC = ({
  jwt,
  url,
}: {
  jwt: string;
  url: URL;
}) => {
  url.searchParams.append("embed", "true");

  if (url.hostname === "localhost") {
    url.searchParams.append("token", jwt);
  } else if (
    url.hostname !== "apps.bamelevate.com" &&
    url.hostname !== "apps-uat.bamelevate.com"
  ) {
    // backwards compatibility for streamlit applications that have not been moved
    // behind the app proxy
    url.searchParams.append("token", jwt);
  }

  return (
    <div className="flex w-full h-full">
      <iframe src={url.href} className="grow content-stretch"></iframe>
    </div>
  );
};

export const EmbeddedApplication: React.FC = ({
  origin,
  jwt,
}: {
  type: "STREAMLIT";
  origin: URL;
  jwt: string;
}) => {
  return <EmbeddedStreamlitApplication jwt={jwt} url={origin} />;
};
